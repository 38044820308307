
import { computed, defineComponent, reactive, ref } from 'vue';
import { Card, CardBody, CardTitle, CardImage, TabStrip, TabStripTab } from '@progress/kendo-vue-layout';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';
import router from '@/router';

enum Type {
  MAGIC4 = 1,
  MAGICTOC = 2
}

export default defineComponent({
  components: {
    Banner,
    BreadcrumbWrapper,
    Card,
    CardBody,
    CardTitle,
    CardImage,
    ContentWrapper,
    TabStrip,
    TabStripTab
  },
  setup() {
    const selected = ref(0);

    const tabs = reactive(['전체', 'MAGIC4', 'MAGICTOC']);

    const cards = reactive([
      {
        id: 1,
        type: Type.MAGIC4,
        title: '[MAGIC4 임상동영상] Magic4 사용법',
        src: 'https://osstem-web-data.s3.ap-northeast-2.amazonaws.com/IMAGES/IMAGES001/publicRelations/magic4.png',
        createdDate: '2024-08-21'
      },
      {
        id: 2,
        type: Type.MAGICTOC,
        title: '[MAGICTOC 임상동영상] MagicToc 사용법',
        src: 'https://osstem-web-data.s3.ap-northeast-2.amazonaws.com/IMAGES/IMAGES001/publicRelations/magictoc.png',
        createdDate: '2024-08-21'
      }
      // {
      //   id: 3,
      //   type: Type.MAGIC_DENTURE,
      //   title: '[MAGIC Denture 임상동영상] 6. 고딕아치 트레이싱 및 Bite 채득',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/IMAGES001/fa7d07ff-77df-4cb1-9863-bfc956d31b5d.png',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 4,
      //   type: Type.MAGIC_DENTURE,
      //   title: '[MAGIC Denture 임상동영상] 5. Center pin 조정',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/IMAGES001/48572580-6c19-4d6b-b2cb-5e6a1ca338ba.PNG',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 5,
      //   type: Type.MAGIC_DENTURE,
      //   title: '[MAGIC Denture 임상동영상] 4. VD(수직고경)측정을 위한 트레이 분리',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/05c696ac-5d93-44d6-b41e-d8e52c90d31b.png',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 6,
      //   type: Type.MAGIC_DENTURE,
      //   title: '[MAGIC Denture 임상동영상] 3. 노출된 트레이 부분 다듬기',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/78526929-a8a7-4748-9160-52c23fd9e7af.png',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 7,
      //   type: Type.MAGIC_DENTURE,
      //   title: '[MAGIC Denture 임상동영상] 2. Lightbody 보더몰딩',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/IMAGES001/1538ee54-db0b-449b-a851-eb17d06e2b73.png',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 8,
      //   type: Type.MAGIC_DENTURE,
      //   title: '[MAGIC Denture 임상동영상] 1. Heavybody 보더몰딩',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/IMAGES001/874a7c2d-b928-42dc-bc4e-a4b4b92b253f.png',
      //   createdDate: '2018-10-29'
      // },
      // {
      //   id: 9,
      //   type: Type.MAGIC_CORRECT,
      //   title: '임상증례Case-01',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/b107f86b-09a5-4b42-ac0a-b0ff3b0b871f.jpg',
      //   createdDate: '2017-10-27'
      // },
      // {
      //   id: 10,
      //   type: Type.MAGIC_POSITIONER,
      //   title: 'MAGIC Positoiner bonding 동영상',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/IMAGES002/8a1637b2-62f1-489e-a534-2e2884f0bcff.jpg',
      //   createdDate: '2017-10-26'
      // },
      // {
      //   id: 11,
      //   type: Type.MAGIC_RETAINER,
      //   title: 'MAGIC Retainer bonding 동영상',
      //   src: 'http://dz8oqzgwcs3uf.cloudfront.net/IMAGES/IMAGES002/ba256e92-5de5-4330-8f5b-e818a49b12e2.jpg',
      //   createdDate: '2017-10-26'
      // }
    ]);

    const getCardsByType = computed(() => {
      const data = (type => {
        switch (type) {
          case Type.MAGIC4:
            return cards.filter(v => v.type === Type.MAGIC4);
          case Type.MAGICTOC:
            return cards.filter(v => v.type === Type.MAGICTOC);
          default:
            return cards;
        }
      })(selected.value as Type);

      return {
        total: data.length,
        data
      };
    });

    const handleSelect = (event: any) => {
      selected.value = event.selected;
    };

    const handleClick = (id: number) => {
      router.push(`/pr/library/${id}`);
    };

    return { selected, tabs, cards, getCardsByType, handleSelect, handleClick };
  }
});
